import styles from './index.module.scss';
import Header from "../../components/header";
import Footer from "../../components/footer";
import * as React from "react";

type PageTemplateProp = {
    children?:  JSX.Element;
}
const PageTemplate = ({children}: PageTemplateProp) => {
    return (
        <div className={styles.container}>
            <Header/>
            {/* <header className={styles.header}>
            </header> */}
            <div className={styles.content}>
                {children}
            </div>
            <Footer/>
        </div>
    )
}
export default PageTemplate;