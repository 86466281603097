import styles from './index.module.scss';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../pageTemplate';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CampaignList from 'src/components/campaignList';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import BodyCheckTab from '../bodyCheckTab';
import VaccinationTab from '../vaccinationTab';

type JSONObject = {
    title: string;
    cover: string;
    back: string;
    pdf: string;
};

const tabs = ['body-check', 'vaccination'];

const Home = () => {
    const history = useHistory();
    const page = new URLSearchParams(useLocation().search).get('page');
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const filePath = require('../../static/plan/plan-match.json');

    useEffect(() => {
        console.log(page);

        if (!page) {
            setValue(0);
        } else {
            setValue(Math.max(0, tabs.indexOf(page)));
        }
    }, [page]);

    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // setValue(value);
        history.replace('/?page=' + tabs[value]);
    };

    const getCampaignData = (category: string) => {
        return filePath[category].map((d: JSONObject) => ({
            ...d,
            title: t(d.title),
        }));
    };

    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#009E96',
            },
        },
    });

    return (
        <PageTemplate>
            <ThemeProvider theme={customTheme}>
                <Paper square className={styles.container}>
                    <Tabs
                        className={styles.tabs}
                        classes={{
                            indicator: styles.indicator,
                        }}
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon tabs example"
                        TabIndicatorProps={{ style: { background: '#009E96' } }}
                    >
                        <Tab className={styles.tab} label={t('BodyCheck.title')} />
                        <Tab className={styles.tab} icon={t('Vaccination.title')} />
                    </Tabs>
                    {value === 0 ? <CampaignList items={getCampaignData('bodyCheck')} /> : <CampaignList items={getCampaignData('vaccination')} />}
                </Paper>
            </ThemeProvider>
        </PageTemplate>
    );
};
export default Home;
