import Home from "./pages/home";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import DetailPage from "./pages/detail";
import NotFound from "./pages/notFound";
import React from "react";
import "./i18n"

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/details" component={DetailPage}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
}

export default App;
