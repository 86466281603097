import i18n from 'i18next';
import translateEN from './static/locales/en/common.json'
import translateTC from './static/locales/tc/common.json'
import {TC} from "./utils/constant";
import {initReactI18next} from "react-i18next";

const resource = {
    en: {translation: translateEN},
    tc: {translation: translateTC}
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: TC,
        resources: resource,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

export default i18n;