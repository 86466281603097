import styles from './index.module.scss';
import mccLogo from "../../static/images/mcc-logo.png"
import FacebookLogo from "../../static/images/media-svg/facebookLogo.svg";
import InstagramLogo from '../../static/images/media-svg/instagramLogo.svg';
import LinkedInLogo from '../../static/images/media-svg/linkedinLogo.svg';
import YouTubeLogo from '../../static/images/media-svg/youtubeLogo.svg';
import TwitterLogo from '../../static/images/media-svg/twitterLogo.svg';
import {useTranslation} from 'react-i18next';
import PhoneIcon from '../../static/images/icon-svg/phoneLogo.svg';
import MailOutlineIcon from '../../static/images/icon-svg/emailLogo.svg';
import {Divider} from '@material-ui/core';
import {emailAddress, phoneNo} from "../../utils/constant";

const Footer = () => {
    const {t} = useTranslation()
    const data = [
        {
            id: "facebookIcon",
            path: "https://www.facebook.com/MediConCen/",
            src: FacebookLogo
        },
        {
            id: "instagramIcon",
            path: "https://www.instagram.com/mediconcen/",
            src: InstagramLogo
        },
        {
            id: "youtubeIcon",
            path: "https://www.youtube.com/watch?v=Hw8nTTMoE8Y",
            src: YouTubeLogo
        },
        {
            id: "LinkedInIcon",
            path: "https://hk.linkedin.com/company/mediconcen",
            src: LinkedInLogo
        },
        {
            id: "twitterIcon",
            path: "https://twitter.com/mediconcen",
            src: TwitterLogo
        },
    ]

    return (
        <div className={styles.container}>
            <img className={styles.icon} src={mccLogo} alt={"mcc-logo"}/>
            <div className={styles.mediaIcon}>
                {data.map((item, index) => {
                    return (
                        <a key={item.id} href={item.path} className={styles.anchor} target="_blank" rel="noreferrer">
                            <img src={item.src} className={styles.img} alt={item.id}/>
                        </a>
                    )
                })}
            </div>
            <div className={styles.contactUs}>
                <span className={styles.title}>{t("Footer.contactUsTitle")}</span>
                <div className={styles.contactUsComponent}>
                    <img src={PhoneIcon} className={styles.startIcon} alt={"PhoneIcon"}/>
                    <a className={styles.anchor} href={`tel:${phoneNo}`}>{phoneNo}</a>
                </div>

                <div className={styles.contactUsComponent}>
                    <img src={MailOutlineIcon} className={styles.startIcon} alt={"MailOutlineIcon"}/>
                    <a className={styles.anchor} href={`mailto:${emailAddress}`}>{emailAddress}</a>
                </div>
            </div>

            <Divider light={true} classes={{root: styles.divider}}/>
            <span className={styles.claims}>{t("Footer.claims")}</span>
        </div>
    )
}

export default Footer;