import styles from './index.module.scss';
import mccLogo from '../../static/images/mcc-logo.png';
import PhoneIcon from '../../static/images/icon-svg/phoneLogo.svg';
import {phoneNo} from "../../utils/constant";


const Header = () => {
    return (
        <div className={styles.container}>
            <img className={styles.mccLogo} src={mccLogo} alt={'mcc-logo'} />
            <div className={styles.tel}>
                <img src={PhoneIcon} className={styles.icon} alt={'PhoneIcon'} />
                <a className={styles.anchor} href={`tel:${phoneNo}`}>{phoneNo}</a>
            </div>
        </div>
    );
};

export default Header;
