import React from 'react';
import PageTemplate from "../pageTemplate";

const NotFound = () => (
    <PageTemplate>
        <div>
            <h1>404 - Not Found!</h1>
        </div>
    </PageTemplate>
);

export default NotFound;