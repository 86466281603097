import { Link } from "react-router-dom";
import styles from "./index.module.scss";

type CampaignItemProps = {
  title: string;
  cover: string;
  back: string;
  pdf: string;
};

const CampaignItem = (props: CampaignItemProps) => {
  return (
    <div className={styles.campaignItem}>
      <div className={styles.banner}>
        <Link
          to={{
            pathname: "/details",
            state: {
              title: props.title,
              pdfPath: props.pdf,
              coverImgPath: props.cover,
              backImgPath: props.back,
            },
          }}
        >
          <img src={props.cover} alt={props.title} />
        </Link>
      </div>
      <div className={styles.title}>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default CampaignItem;
