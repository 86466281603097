import CampaignItem from "../campaignItem";
import styles from "./index.module.scss";

type CampaignListProps = {
  items: Array<{
    title: string;
    cover: string;
    back: string;
    pdf: string;
  }>;
};

const CampaignList = (props: CampaignListProps) => {
  const renderCampaignItems = () => {
    return props.items.map((i, index) => <CampaignItem key={index} {...i} />);
  };

  return <div className={styles.campaignList}>{renderCampaignItems()}</div>;
};

export default CampaignList;
