import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../pageTemplate';
import styles from './index.module.scss';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

interface Props {
    title: string;
    coverImgPath: string;
    backImgPath: string;
    pdfPath: string;
}

const DetailPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<Props>();
    const props = location.state;
    // console.log(props)

    // TODO: Update Router Setting
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    if (location.state === undefined) {
        return <Redirect to={'/'} />;
    }

    return (
        <PageTemplate>
            <div className={styles.container}>
                <Button className={styles.lastPage} onClick={history.goBack} startIcon={<ChevronLeftIcon />}>
                    {t('Detail.lastPage')}
                </Button>
                <div className={styles.header}>
                    <span className={styles.title}>{t(location.state.title)}</span>
                    <Button startIcon={<SystemUpdateAltIcon />} className={styles.button}>
                        <a href={location.state.pdfPath} download className={styles.anchor}>
                            {t('Detail.downloadPDF')}{' '}
                        </a>
                    </Button>
                </div>
                <div className={styles.row}>
                    <img className={styles.img} src={location.state.coverImgPath} alt={location.state.title} />
                    <img className={styles.img} src={props.backImgPath} alt={location.state.title} />
                </div>
            </div>
        </PageTemplate>
    );
};
export default DetailPage;
